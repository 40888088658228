import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import MdxLink from "./mdxLink"
import Header from "./header"
import styles from "./layout.module.css"

const LocaleContext = React.createContext();

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale, slug } }) => {
  const data = useStaticQuery(graphql`
    query SiteFooterQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <LocaleContext.Provider value={{ locale }}>
    <div className={styles.globalWrapper}>
      <Header slug={slug} className={styles.globalHeader} siteTitle={"Personal Website"} />
      
      <MDXProvider components={{ a: MdxLink }}>
        <main>{children}</main>
        <footer>
          <span>© {new Date().getFullYear()}, {data.site.siteMetadata.author}</span>
        </footer>
      </MDXProvider>
    </div>

  </LocaleContext.Provider>
  )
}

export { Layout, LocaleContext }