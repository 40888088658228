import React from "react"
import LocalizedLink from "./localizedLink"

const isHash = str => /^#/.test(str)
const isInternal = to => /^\/(?!\/)/.test(to)
/* eslint-disable */

// Only use <LocalizedLink /> for internal links
const MdxLink = ({ href, ...props }) =>{

 return( isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} />
  ) : (
    <LocalizedLink {...props} to={href} />
  )
 )
}
export default MdxLink

