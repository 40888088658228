import PropTypes from "prop-types"
import React from "react"

import Navigation from "./navigation"

const Header = (props) => (
  <header className={props.className}>
      <Navigation currentSlug={props.slug} />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
