import React from "react"
import { Link } from "gatsby"
import { AiOutlineHome } from "react-icons/ai"
import ReactCountryFlag from "react-country-flag"

import LocalizedLink from "./localizedLink"
import useTranslations from "./useTranslations"
import styles from "./layout.module.css"

const Navigation = (props) => {
  const { backToHome } = useTranslations()
  
  return (
    <nav>
      <LocalizedLink to="/" aria-label={backToHome}>
        <AiOutlineHome style={{fontSize:"1.5em", color: "#FFF"}}/>
      </LocalizedLink>
      <div className={styles.languageChoices}>
        <Link className={styles.localeChangeLink} to="/" hrefLang="en">
          <ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="GB"
                svg
                cdnUrl="https://cdn.jsdelivr.net/npm/flag-icon-css@3.4.6/flags/4x3/"
                cdnSuffix="svg"
                aria-label="Great Britan flag"
                title="English"
            />
        </Link>

        <Link className={styles.localeChangeLink} to="/es" hrefLang="es">
          <ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="ES"
                svg
                cdnUrl="https://cdn.jsdelivr.net/npm/flag-icon-css@3.4.6/flags/4x3/"
                cdnSuffix="svg"
                aria-label="Spain flag"
                title="Español"
            />
        </Link>

        <Link className={styles.localeChangeLink} to="/de" hrefLang="de">
          <ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="DE"
                svg
                cdnUrl="https://cdn.jsdelivr.net/npm/flag-icon-css@3.4.6/flags/4x3/"
                cdnSuffix="svg"
                aria-label="Germany flag"
                title="Deutsch"
            />
        </Link>
        
        <Link className={styles.localeChangeLink} to="/pt" hrefLang="pt">
          <ReactCountryFlag
                className={styles.emojiFlag}
                countryCode="PT"
                svg
                cdnUrl="https://cdn.jsdelivr.net/npm/flag-icon-css@3.4.6/flags/4x3/"
                cdnSuffix="svg"
                aria-label="Portugal flag"
                title="Português"
            />
        </Link>
      </div>
    </nav>
  )
}

export default Navigation