// Only one item MUST have the "default: true" key

module.exports = {
    en: {
      default: true,
      path: `en`,
      locale: `en-US`,
      dateFormat: `YYYY/MM/DD`,
      siteLanguage: `en`,
      ogLanguage: `en_US`,
      defaultTitle: `Using i18n with Gatsby`,
      defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
    },
    es: {
      path: `es`,
      locale: `es-HN`,
      dateFormat: `DD/MM/YYYY`,
      siteLanguage: `es`,
      ogLanguage: `es_HN`,
      defaultTitle: `Usando i18n con Gatsby`,
      defaultDescription: `Sitio de ejemplo de Gatsby utilizando MDX y i18n libre de dependencias`,
    },
    de: {
      path: `de`,
      locale: `de-DE`,
      dateFormat: `DD.MM.YYYY`,
      siteLanguage: `de`,
      ogLanguage: `de_DE`,
      defaultTitle: `i18n mit Gatsby nutzen`,
      defaultDescription: `Gatsby Beispielseite, die MDX und i18n (frei von dependencies) nutzt`,
    },
    pt: {
      path: `pt`,
      locale: `pt-BR`,
      dateFormat: `DD.MM.YYYY`,
      siteLanguage: `pt`,
      ogLanguage: `pt_BR`,
      defaultTitle: `Usando i18n com Gatsby`,
      defaultDescription: `Sitio teste de Gatsby usando  MDX e i18n (livre de dependencias)`,
    },
  }